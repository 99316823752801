<i18n src="@/locales/basal_dental_implant.json" />
<i18n src="@/locales/articles-card-content.json" />

<template>
  <div class="basal-dental-implant">
    <div class="header-background">
      <ImanHeader
        :title="$t('h1_title')"
      />
    </div>
    <b-message>
      <span v-html="$t('message_1')" />
    </b-message>
    <ImanH2>{{ $t('h2_title_1') }}</ImanH2>
    <section :class="mainBackgroundClass + ` section`">
      <ImanHomeSection :img="img[0]">
        <ImanH3>{{ $t('h3_title_1') }}</ImanH3>
        <p>
          <span v-html="$t('article_p1')" />
        </p>
      </ImanHomeSection>
      <ImanHomeSection :img="img[1]">
        <ImanH3>{{ $t('h3_title_2') }}</ImanH3>
        <p>
          {{ $t('article_p2') }}
        </p>
      </ImanHomeSection>
    </section>
    <ImanH2><span v-html="$t('h2_title_2')" /></ImanH2>
    <section :class="mainBackgroundClass + ` section`">
      <ImanHomeSection :img="img[2]">
        <p>
          {{ $t('article_p3') }}
        </p>
        <ImanMoreInfo :path="$t('more_info_path_1')">
          {{ $t('more_info_1') }}
        </ImanMoreInfo>
      </ImanHomeSection>
      <ImanHomeSection :img="img[3]">
        <ImanH3>{{ $t('h3_title_3') }}</ImanH3>
        <p>
          <span v-html="$t('article_p4')" />
        </p>
        <ImanMoreInfo :path="$t('more_info_path_2')">
          {{ $t('more_info_2') }}
        </ImanMoreInfo>
      </ImanHomeSection>
      <ImanHomeSection :img="img[4]">
        <ImanH3>{{ $t('h3_title_4') }}</ImanH3>
        <p>
          {{ $t('article_p5') }}
        </p>
        <ImanMoreInfo :path="$t('more_info_path_3')">
          {{ $t('more_info_3') }}
        </ImanMoreInfo>
      </ImanHomeSection>
    </section>
    <ImanH2>{{ $t('h2_title_3') }}</ImanH2>
    <section class="columns is-centered background-section">
      <div class="column is-8">
        <ImanCollapsableCard
          :title="$t('collapse_title_1')"
        >
          <p>
            <span v-html="$t('collapse_p1_1')" />
          </p>
          <ImanMoreInfo :path="$t('more_info_path_4')">
            {{ $t('more_info_4') }}
          </ImanMoreInfo>
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_2')"
        >
          <p>
            <span v-html="$t('collapse_p2')" />
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_3')"
        >
          <p>
            {{ $t('collapse_p3') }}
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_4')"
        >
          <p>
            {{ $t('collapse_p4') }}
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_5')"
        >
          <p>
            <span v-html="$t('collapse_p5')" />
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_6')"
        >
          <p>
            <span v-html="$t('collapse_p6')" />
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_7')"
        >
          <p>
            {{ $t('collapse_p7') }}
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_8')"
        >
          <p>
            <span v-html="$t('collapse_p8')" />
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_9')"
        >
          <p>
            {{ $t('collapse_p9') }}
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_10')"
        >
          <p>
            {{ $t('collapse_p10_1') }}
          </p>
          <p>
            <span v-html="$t('collapse_p10_2')" />
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_11')"
        >
          <p>
            {{ $t('collapse_p11') }}
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_12')"
        >
          <p>
            {{ $t('collapse_p12') }}
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_13')"
        >
          <p>
            {{ $t('collapse_p13') }}
          </p>
          <ImanMoreInfo :path="$t('more_info_path_5')">
            {{ $t('more_info_5') }}
          </ImanMoreInfo>
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_14')"
        >
          <p>
            <span v-html="$t('collapse_p14')" />
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_15')"
        >
          <p>
            <span v-html="$t('collapse_p15')" />
          </p>
        </ImanCollapsableCard>
      </div>
    </section>
    <ImanH2>{{ $t('h2_articles') }}</ImanH2>
    <ImanArticleSection :content-list="filteredArticlesCardContent" />
  </div>
</template>

<script>
  import {articlesCardContentFilterMixin} from "@/mixins/articles-card-content-filter-mixin";
  import ImanArticleSection from "@/components/ImanArticleSection";
  import {metaMixin} from "@/mixins/meta-mixin";

  export default {
    name: 'BasalDentalImplant',
    components: {
      ImanArticleSection
    },
    mixins: [
      articlesCardContentFilterMixin,
      metaMixin
    ],
    data() {
      return {
        mainBackgroundClass: 'none-background',
        articlesCardContentId: [223, 224, 65, 84],
        img: [
          {
            path: 'dental-implant/basal-implant/implant-basal-caracteristique.png',
            position: 'right',
            alt: 'qu\'est ce qu\'un implant basal'
          },
          {
            path: 'dental-implant/basal-implant/implant-basal-definition.png',
            position: 'right',
            alt: 'Définition de l\'implant basal'
          },
          {
            path: 'dental-implant/basal-implant/implant-basal-derniere-generation.jpg',
            position: 'right',
            alt: 'implant basal de dernière génération lequel choisir'
          },
          {
            path: 'dental-implant/basal-implant/implant-dentaire-basal-pose.jpg',
            position: 'right',
            alt: 'pose d\'un implant basal par le Prof. Ihde'
          },
          {
            path: 'dental-implant/basal-implant/prothese-fixe-implants-dentaires.jpg',
            position: 'right',
            alt: 'pose d\'une prothèse dentaire fixe sur implant basal'
          },
          {
            path: 'dental-implant/basal-implant/prothese-fixe-implants-dentaires.jpg',
            position: 'right',
            alt: 'prothèse fixe sur implant dentaire'
          },
        ]
      }
    },
    computed: {
      openGraphImgPath () {
        return 'https://anveli.dental' + require('../../../assets/img/thumbnail/MINIATURE_2.2.png')
      }
    },
    created() {
      window.addEventListener('scroll', this.whenScroll)
    },
    destroyed() {
      window.removeEventListener('scroll', this.whenScroll)
    },
    methods: {
      whenScroll() {
        if (document.body.scrollTop > 1 || document.documentElement.scrollTop > 1) {
          this.mainBackgroundClass = 'none-background'
        }
        if (document.body.scrollTop > 1000 || document.documentElement.scrollTop > 1000) {
          this.mainBackgroundClass = 'fixed-background'
        }
        if (document.body.scrollTop > 3900 || document.documentElement.scrollTop > 3900) {
          this.mainBackgroundClass = 'none-background'
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .header-background {
    @media only screen and (min-width: 768px) {
      /* tablet et ordinateurs */
      background-image: url("../../../assets/img/dental-implant/basal-implant/H1-implant-basal-d.jpg");
    }
    @media only screen and (max-width: 767px) {
      /* smartphone en mode paysage */
      background-image: url("../../../assets/img/dental-implant/basal-implant/H1-implant-basal-t.jpg");
    }
    @media only screen and (max-width: 767px) and (orientation: portrait) {
       /* smartphone en mode portait */
      background-image: url("../../../assets/img/dental-implant/basal-implant/H1-implant-basal-m.jpg");
    }
  }
</style>
